import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../../services/session-storage/local-storage.service';
import {AppConfig} from "../../../configs/app-config";
import {jwtDecode} from "jwt-decode";
import {environment} from "../../../configs/environment";


@Injectable({
  providedIn: 'root'
})
export class LoggedCIAMInGuard implements CanActivate, CanActivateChild {
  constructor(
                private sessionStorageService: SessionStorageService,
                private route: Router
            ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.verifyLogin();

  }

  verifyLogin(): boolean {

    if (localStorage.getItem(AppConfig.SSO_CIAM())) {
      let isExpired = this.isTokenExpired(localStorage.getItem(AppConfig.SSO_CIAM()));

      if(isExpired){
        location.href = environment.SSO_ENDPOINT;
        return false;
      }else{
        return true;
      }

      return true;
    }

    location.href = environment.SSO_ENDPOINT;
    return false;
  }

  isTokenExpired(token: string): boolean  {

    try {
      const decoded: any = jwtDecode(token);

      if (!decoded.exp) {
        return true; // Si no hay campo `exp`, se considera expirado
      }

      const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
      return decoded.exp < currentTime;
    } catch (error) {
      console.error('Error al decodificar el token:', error);
      return true; // Si ocurre un error, se considera expirado
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next,state);
  }

  isLoggedCIAM(): boolean {
    let dataSession= this.sessionStorageService.getItemSessionStorage("sso_persona");
    if(dataSession){
      return true;
    }else{
      const baseUrl = window.location.origin;
      const redirectPath = '/gestionar-pedido';
      const fullRedirectUrl = baseUrl + redirectPath;
      const homeRedirectUrl = baseUrl + '/inicio';
      if(window.location.href === fullRedirectUrl){
        window.location.href = homeRedirectUrl;
        alert('Debe iniciar sesión para acceder a esta opción.');
      }

      return false;
    }

  }



}

